import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/Container"
import {SectionTitle} from "../components/SectionTitle/styles"
import FlexibleIcon from "../images/flexible.svg"
import CustomizeIcon from "../images/custom.svg"
import OptimizedIcon from "../images/optimized.svg"
import CirclesFour from "../images/circlesfour.svg"
import Monitor from "../images/monitor.svg"
import Support from "../images/support.png"
import FixedFee from "../images/fixedfee.svg"
import Budget from "../images/budget.svg"
import {ButtonGf, ButtonLink, ButtonWrapper, PopupText} from "../components/Button/styles";
import { colors } from "../const/colors"
import {IoCheckmark, IoSpeedometer} from "react-icons/io5"
import {InfoBox} from "../components/Footer/styles";
import {BannerSection, BenefitsSection} from "../components/WebsitePage/styles";
import CircleTwo from "../images/greyCirclesLeft.svg";
import CircleThree from "../images/grayCirclesRight.svg";
import {DevelopmentSection, LaunchAppSection, SupportSection} from "../components/ApplicationPage/styles";
import { BannerStyles} from "../const/inline_styles";
import AnimatedInViewWrapper from "../components/AnimatedInViewWrapper";
import localPageData from "../localization/application.json"
import Img from "gatsby-image"
import localData from "../localization/application.json";

// const flags = {
//     "en": localData.locales[0].value,
//     "es": localData.locales[1].value
// }
const flags = [
    {
        name: "en",
        uri: localData.locales[0].value
    },
    {
        name: "es",
        uri : localData.locales[1].value
    }
]

const GfApplication = ({data, location, pageContext}) => {
    const localizedImages = {
        "support": {
            "en": data.supportEn.childImageSharp.fluid,
            "es": data.supportEs.childImageSharp.fluid
        }
    }
    const { lang } = pageContext
    const meta = data?.template?.locales.find(l => l.iso === lang)
    const metaStatic = localData.locales.find(l => l.iso === lang)
    const title = localPageData.locales.find(l => l.iso === pageContext.lang).pageTitle
    const heroSectiondData = localPageData.sec.hero.locales.find(l => l.iso === pageContext.lang)
    const benefitSectiondData = localPageData.sec.benefits.locales.find(l => l.iso === pageContext.lang)
    const devSectionData = localPageData.sec.development.locales.find(l => l.iso === pageContext.lang)
    const supportSectionData = localPageData.sec.support.locales.find(l => l.iso === pageContext.lang)
    const launchSectionData = localPageData.sec.launch.locales.find(l => l.iso === pageContext.lang)

    return <Layout location={location} lang={pageContext.lang} flags={flags}>
            <SEO
                title={ meta?.metaTitle || metaStatic.metaTitle}
                description={ meta?.metaDescription || metaStatic.metaDescription}
                keywords={ meta?.metaKeywords || metaStatic.metaKeywords}
            />
      <BannerSection>
          <Container>
              <h2 dangerouslySetInnerHTML={{__html: title }} style={BannerStyles.h2} />
              <SectionTitle>
                  {heroSectiondData.title}
              </SectionTitle>
              <p style={{maxWidth:"900px"}}>{heroSectiondData.description}</p>
              <div className="info-box-list">
                  <InfoBox step={1} icon={<img src={FlexibleIcon} alt="flexible" />} text={heroSectiondData.group.first} color={colors.grayColor} />
                  <InfoBox step={2} size="27px" icon={<img src={CustomizeIcon} alt="customized" />} text={heroSectiondData.group.second} color={colors.grayColor} />
                  <InfoBox step={3} icon={<img src={OptimizedIcon} alt="optimized" />} text={heroSectiondData.group.third} color={colors.grayColor} />
              </div>
              <div className="free-consultation">
                  <PopupText text={heroSectiondData.button.label} url="https://calendly.com/tina-greenflamingo/30min" />
              </div>
          </Container>
          <div className="bg-image">
              <img src={CirclesFour} alt="circles"/>
          </div>
      </BannerSection>
      <BenefitsSection>
          <Container>
              <SectionTitle align="center">{benefitSectiondData.title}</SectionTitle>
              <div className="content">
                  <p style={{textAlign:"center"}}>{benefitSectiondData.description}</p>

                  <ul>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html:benefitSectiondData.group.first}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html:benefitSectiondData.group.second}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html:benefitSectiondData.group.third}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html:benefitSectiondData.group.fourth}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html:benefitSectiondData.group.fifth}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html:benefitSectiondData.group.sixth}} /></li>
                  </ul>
                  <div className="get-in-touch">
                      <h4>{benefitSectiondData.subSectionTitle}</h4>
                      <span>{benefitSectiondData.subSectionDescription}</span>
                      {/*<ButtonGf>Start</ButtonGf>*/}
                      <div className="free-consultation free-consultation--flex">
                          <PopupText url="https://calendly.com/tina-greenflamingo/30min" text={benefitSectiondData.button.label} />
                      </div>
                  </div>
              </div>
          </Container>
          <img className="left" src={CircleTwo} alt="circles two"/>
          <img className="right" src={CircleThree} alt="circles three"/>
      </BenefitsSection>
      <DevelopmentSection>
          <Container>
              <SectionTitle align="center">{devSectionData.title}</SectionTitle>
              <div className="content">
                  <p>{devSectionData.description}</p>
                  <div className="main-content">
                      <div className="img-block">
                          <AnimatedInViewWrapper initial="hidden"
                                                 variants={{
                                                     visible: {
                                                         y:0,
                                                         opacity:1,
                                                         transition: {
                                                             delay:.3,
                                                             duration:.6
                                                         }
                                                     },
                                                     hidden: {
                                                         y:"50px",
                                                         opacity:0,
                                                         transition: {
                                                             duration:.6
                                                         }
                                                     }
                                                 }}>
                          <img src={Monitor} alt="monitor"/>
                          </AnimatedInViewWrapper>
                      </div>
                      <ul>
                          <li><IoCheckmark color={colors.greenColor} /><span>{devSectionData.group.first}</span></li>
                          <li><IoCheckmark color={colors.greenColor} /><span>{devSectionData.group.second}</span></li>
                          <li><IoCheckmark color={colors.greenColor} /><span>{devSectionData.group.third}</span></li>
                          <li><IoCheckmark color={colors.greenColor} /><span>{devSectionData.group.fourth}</span></li>
                          <li><IoCheckmark color={colors.greenColor} /><span>{devSectionData.group.fifth}</span></li>
                          <li><IoCheckmark color={colors.greenColor} /><span>{devSectionData.group.sixth}</span></li>
                          <li><IoCheckmark color={colors.greenColor} /><span>{devSectionData.group.seventh}</span></li>
                          <li><IoCheckmark color={colors.greenColor} /><span>{devSectionData.group.eight}</span></li>
                          <li><IoCheckmark color={colors.greenColor} /><span>{devSectionData.group.nine}</span></li>
                      </ul>
                  </div>
                  <div className="button-wrapper">
                      <ButtonLink to={devSectionData.button.link}  color="white" background={colors.greenColor} bgHoverColor={colors.grayColor}>{devSectionData.button.label}</ButtonLink>
                  </div>
              </div>
          </Container>
      </DevelopmentSection>
      <SupportSection imageLeftPosition="47px">
          <Container>
              <SectionTitle align="center">{supportSectionData.title}</SectionTitle>
              <div className="content">
                  <div style={{width:"50%"}} className="img-block">
                      {/*<img src={Support} alt=""/>*/}
                      <Img fluid={localizedImages.support[pageContext.lang]} />
                  </div>
                  <span>{supportSectionData.picTitle}</span>
                  <p>{supportSectionData.description}</p>
              </div>
          </Container>
      </SupportSection>
      <LaunchAppSection>
          <Container>
              <SectionTitle align="center">{launchSectionData.title}</SectionTitle>
              <p style={{textAlign:"center"}}>{launchSectionData.description}</p>
              <div className="box-wrapper">
                  <div className="box">
                      <AnimatedInViewWrapper
                          styles={{
                              width:"60px"
                          }}
                      initial="hidden"
                      variants={
                          {
                              visible: {
                                  y:0,
                                  opacity:1,
                                  transition: {
                                      duration:.6
                                  }
                              },
                              hidden: {
                                  y:"20px",
                                  opacity:0,
                                  transition: {
                                      duration:.6
                                  }
                              }
                          }
                      }
                      >
                          <img src={FixedFee} alt=""/>
                      </AnimatedInViewWrapper>
                      <span>{launchSectionData.groupModule.first.title}</span>
                      <p>{launchSectionData.groupModule.first.description}</p>
                  </div>
                  <div className="box">
                      <AnimatedInViewWrapper styles={{
                          width:"60px"
                      }}
                                             initial="hidden"
                                             variants={
                                                 {
                                                     visible: {
                                                         y:0,
                                                         opacity:1,
                                                         transition: {
                                                             duration:.6,
                                                             delay:.4
                                                         }
                                                     },
                                                     hidden: {
                                                         y:"20px",
                                                         opacity:0,
                                                         transition: {
                                                             duration:.6
                                                         }
                                                     }
                                                 }
                                             }>
                          <img src={Budget} alt=""/>
                      </AnimatedInViewWrapper>

                      <span>{launchSectionData.groupModule.second.title}</span>
                      <p>{launchSectionData.groupModule.second.description}</p>
                  </div>
              </div>
              {/*<div className="button-wrapper">*/}
              {/*    <ButtonGf background={colors.greenColor} color="white" bgHoverColor={colors.grayColor}>Learn more</ButtonGf>*/}
              {/*</div>*/}
          </Container>
      </LaunchAppSection>
  </Layout>
}

export const query = graphql`
  {
    supportEs: file(relativePath: {eq: "support_es.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    supportEn: file(relativePath: {eq: "support.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`


export default GfApplication
